// export const baseUrl = "http://192.168.0.3:8080";
// export const baseUrl = "http://192.168.0.14/ardon_laravel/public";
export const baseUrl = process.env.REACT_APP_BASE_URL;

export const token = localStorage.getItem("token");

export const navbarContentUrl = `${baseUrl}/api/navbar`;
export const productsListUrl = `${baseUrl}/api`;
export const getSingProductUrl = `${baseUrl}/api/detail`;
export const singupUrl = `${baseUrl}/api/v1/register`;
export const singinUrl = `${baseUrl}/api/v1/login`;
export const cartDetailsUrl = `${baseUrl}/api/v1/cart-detail`;
export const addToCartUrl = `${baseUrl}/api/v1/add-to-cart`;
export const removeFromCartUrl = `${baseUrl}/api/v1/remove-to-cart`;
export const increaseCartProductUrl = `${baseUrl}/api/v1/increase-product`;
export const decreaseCartProductUrl = `${baseUrl}/api/v1/decrease-product`;
export const updateCartProductSizeUrl = `${baseUrl}/api/v1/update-size`;
export const saveShippingAddressUrl = `${baseUrl}/api/v1/store-address`;
export const updateShippingAddressUrl = `${baseUrl}/api/v1/update-address`;
export const deleteShippingAddressUrl = `${baseUrl}/api/v1/delete-address`;
export const saveBillingAddressUrl = `${baseUrl}/api/v1/store-address`;
export const placeOrderUrl = `${baseUrl}/api/v1/place-order`;
export const getStateUrl = `${baseUrl}/api/v1/state`;
export const getCountryUrl = `${baseUrl}/api/v1/get-country`;
export const getUserAddressUrl = `${baseUrl}/api/v1/get-addresses`;
export const proceedToPaymentUrl = `${baseUrl}/api/v1/payment`;
export const getPlacedOrdersUrl = `${baseUrl}/api/v1/my-orders`;
export const addToFavouriteUrl = `${baseUrl}/api/v1/add-to-favourite`;
export const getFavouriteListUrl = `${baseUrl}/api/v1/favourite-list`;
export const removeFavoriteListUrl = `${baseUrl}/api/v1/remove-favourite`;

export const UserDetails = `${baseUrl}/api/v1/profile`;
export const UpdateRegion = `${baseUrl}/api/v1/region-update`;
export const editUserDetails = `${baseUrl}/api/v1/update-profile`;
export const updatePassword_url = `${baseUrl}/api/v1/update-password`;

export const getPageText_url = `${baseUrl}/api/front-texts`;
export const getHomeReview = `${baseUrl}/api/reviews-home-screen`;

export const getEngagementCollection = `${baseUrl}/api/collection/1`;
export const getWeddingCollection = `${baseUrl}/api/collection/2`;
export const getJewelryCollection = `${baseUrl}/api/collection/3`;

export const getEarringsJewelry = `${baseUrl}/api/jewelry`;
