import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { currencyIcon } from "../../constants/function";
import { cartShippingFields } from "../../constants/utils";
import {
  getCountry,
  getState,
  getUserAddresses,
} from "../../features/address/addressSlice";
import {
  getCartDetails,
  proccedToPayment,
} from "../../features/cart/cartSlice";
import formatCurrency from "../../helper/currencyFormater";
import { userAddressListSchema } from "../../Validation/Schema/UserDetailsSchema";
import AddressCard from "../cart/AddressCard";
import SummaryCartItem from "../cart/SummaryCartItem";
import ListofAddressCard from "../checkout/listofAddressCard";
import NewBillingForm from "../checkout/newBillingForm";
import NewShippingForm from "../checkout/newShippingForm";
import SelectedAddressCard from "../checkout/selectedAddressCard";
import GlobalBreadcrumb from "../common/global-breadcrumb";
import { CustomContainer } from "../common/global-display";
import GlobalForm from "../form/globalForm";
const hideCountryState = "GBP";
function Checkout() {
  const dispatch = useDispatch();
  const countryCurrency = localStorage.getItem("currency") ?? "INR";
  const initialValue = {
    firstname: "",
    lastname: "",
    name: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    postal_code: "",
    country_id: "",
    country: "",
    state_id: "",
  };

  // state
  const [listOfAddress, setListOfAddress] = useState([]);

  const [selectedShippingAddressId, setSelectedShippingAddressId] =
    useState(null);
  const [selectedShippingAddress, setSelectedShippingAddress] = useState(null);

  const [saveShippingAddress, setSaveShippingAddress] = useState(false);

  const [isNewShipping, setIsNewShipping] = useState(false);
  const [isNewShippingForm, setIsNewShippingForm] = useState(false);
  const [newShippingAddress, setNewShippingAddress] = useState({});

  const [isEditShipping, setIsEditShipping] = useState(false);

  useEffect(() => {
    dispatch(getUserAddresses());
    dispatch(getCountry(countryCurrency));
    dispatch(getState(countryCurrency));
  }, [dispatch]);

  const dataFormate = (data) => {
    return {
      ...data,
      firstname: data?.firstname ?? "",
      lastname: data?.lastname ?? "",
      // name: data?.name ?? "",
      name:
        data?.firstname && data?.lastname
          ? `${data?.firstname ?? ""} ${data?.lastname ?? ""}`
          : data?.name ?? "",
      address1: data?.address1 ?? "",
      address2: data?.address2 ?? "",
      city: data?.city ?? "",
      state: `${data?.state?.id}` ?? "",
      postal_code: data?.postal_code ?? "",
      country: `${data?.country?.id}` ?? "",
      country_id: `${data?.country?.id}` ?? "",
      // id: data?.id,
    };
  };

  const userAddress = useSelector((state) => state.address.userAddresses);
  const countryList = useSelector((state) => state?.address?.country);
  const stateList = useSelector((state) => state?.address?.states);

  useEffect(() => {
    setNewShippingAddress({
      ...initialValue,
      country_id:
        countryCurrency == "INR"
          ? "101"
          : countryCurrency == "USD"
          ? "231"
          : countryCurrency == "GBP"
          ? "230"
          : countryCurrency == "CAD"
          ? "31"
          : "101",
    });
  }, [countryList]);

  useEffect(() => {
    setListOfAddress(userAddress);
  }, [userAddress]);

  useEffect(() => {
    if (!!setSelectedShippingAddressId) {
      const add = listOfAddress.find(
        (address) => address.id == selectedShippingAddressId
      );
      setSelectedShippingAddress(dataFormate(add));
    }
  }, [selectedShippingAddressId]);

  //  billing Address

  const [isSameShipping, setIsSameShipping] = useState(false);

  const [isNewBilling, setIsNewBilling] = useState(false);
  const [isNewBillingForm, setIsNewBillingForm] = useState(false);
  const [newBillingAddress, setNewBillingAddress] = useState({});

  const [selectedBillingAddressId, setSelectedBillingAddressId] =
    useState(null);

  const [selectedBillingAddress, setSelectedBillingAddress] = useState(null);

  const [saveBillingAddress, setSaveBillingAddress] = useState(false);

  const [isEditBilling, setIsEditBilling] = useState(false);
  const useDetails = useSelector((state) => state.userDetails.user);
  useEffect(() => {
    setNewBillingAddress({
      ...initialValue,
      country_id:
        countryCurrency == "INR"
          ? "101"
          : countryCurrency == "USD"
          ? "231"
          : countryCurrency == "GBP"
          ? "230"
          : countryCurrency == "CAD"
          ? "31"
          : "101",
    });
  }, []);

  useEffect(() => {
    if (!!selectedBillingAddressId) {
      const add = listOfAddress.find(
        (address) => address.id == selectedBillingAddressId
      );
      setSelectedBillingAddress(dataFormate(add));
    }
  }, [selectedBillingAddressId]);

  useEffect(() => {
    if (!!selectedBillingAddressId) {
      setIsSameShipping(false);
    }
  }, [selectedBillingAddressId]);

  // card details;

  const cartItems = useSelector((state) => state.cart.cartItems);

  useEffect(() => {
    dispatch(getCartDetails());
  }, []);
  const [total, setTotal] = useState(0);
  useEffect(() => {
    if (cartItems && cartItems.length > 0) {
      const totalAmount = cartItems.reduce((acc, curr) => {
        const qty = curr.qty;
        // const price = +curr.product.product_price;
        const price = curr?.amount;

        const itemTotal = qty * price;

        return acc + itemTotal;
      }, 0);

      // Assuming setTotal is a state updater function
      setTotal(totalAmount);
    }
  }, [cartItems]);

  const handleBilling = () => {
    const paymentIfo = {
      // same_as_shipping_address: isSameShipping ? 1 : 0,
      // billing_id: saveBillingAddress,
      // shipping_id: saveShippingAddress,
      same_as_shipping_address:
        selectedShippingAddressId === selectedBillingAddressId ? 1 : 0,
      billing_id: selectedBillingAddressId,
      shipping_id: selectedShippingAddressId,
      created_at: new Date().toUTCString(),
    };

    dispatch(proccedToPayment(paymentIfo));
  };

  const handleFromAddress = (billingData, newAddress) => {
    setNewBillingAddress(billingData);
    setSelectedBillingAddressId(newAddress?.payload?.id);
    setSaveBillingAddress(true);
    setIsNewBillingForm(false);
    setIsEditBilling(false);
  };

  return (
    <>
      <GlobalBreadcrumb subTitle="Checkout" />
      <div className="page-title mb-5">
        <div
          style={{
            color: "#e77e50",
            fontSize: "22px",
            fontWeight: 700,
          }}
        >
          Secure Checkout
        </div>
      </div>

      <CustomContainer className="mt-0 mb-0">
        <div className="row gx-5 justify-content-between">
          {/* Side 1 */}
          <div className="col-lg-7 col-xxl-7">
            <div>
              <h4 className="mb-4 fw-bold">Contact Information</h4>
              <p className="mb-4">{useDetails?.data?.email}</p>
            </div>

            {/*  shipping address */}
            <div className="border-top-2 pt-5 pb-">
              <div>
                <h4 className="fw-bold">Shipping Information</h4>
              </div>

              <div
                className="pt-4"
                style={
                  !!saveShippingAddress && !isNewShippingForm && !isEditShipping
                    ? { height: "230px" }
                    : {}
                }
              >
                <div className="checkout-sec">
                  {isNewShippingForm ? (
                    <NewShippingForm
                      setIsNewShipping={setIsNewShippingForm}
                      setIsNewShippingForm={setIsNewShippingForm}
                      newShippingAddress={newShippingAddress}
                      userAddressListSchema={userAddressListSchema}
                      stateList={stateList}
                      countryList={countryList}
                      setNewShippingAddress={setNewShippingAddress}
                      setSelectedShippingAddressId={
                        setSelectedShippingAddressId
                      }
                      setSaveShippingAddress={setSaveShippingAddress}
                      setIsEditShipping={setIsEditShipping}
                      // cartShippingFields={cartShippingFields}
                      cartShippingFields={
                        countryCurrency === hideCountryState
                          ? cartShippingFields?.filter(
                              (el) => el?.name !== "state_id"
                            )
                          : cartShippingFields
                      }
                    />
                  ) : (
                    <>
                      {isEditShipping ? (
                        <>
                          <CustomContainer
                            className=" contact-form position-relative pb-4"
                            // style={{ marginTop: "80px" }}
                          >
                            <GlobalForm
                              initialValues={selectedShippingAddress}
                              validationSchema={userAddressListSchema}
                              dynamicOption={{
                                state: stateList?.map((stat) => ({
                                  label: stat.name,
                                  value: stat.id,
                                })),
                                country: countryList?.map((stat) => ({
                                  label: stat.name,
                                  value: stat.id,
                                })),
                              }}
                              onSubmit={async (values) => {
                                // setState(values);
                                setSelectedShippingAddress(values);
                                setSaveShippingAddress(true);
                                setIsNewShippingForm(false);
                                setIsEditShipping(false);
                              }}
                              fields={cartShippingFields}
                              btnText="Save & Continue"
                              btnSet="start"
                            />
                          </CustomContainer>
                        </>
                      ) : (
                        <>
                          {saveShippingAddress ? (
                            <SelectedAddressCard
                              isNew={isNewShipping}
                              newAddress={newShippingAddress}
                              selectedAddress={selectedShippingAddress}
                              stateList={stateList}
                              countryList={countryList}
                              backHandler={() => {
                                setSaveShippingAddress(false);
                                // setSelectedShippingAddressId(false);
                                // setSelectedShippingAddress(null);
                                // for billing Address
                                setIsSameShipping(false);
                                setSaveBillingAddress(false);
                                // setSelectedBillingAddress(false);
                                // setSelectedBillingAddressId(null);
                              }}
                            />
                          ) : (
                            <div className="">
                              <div className="row address-card-container  g-3">
                                <div className="col-md-6 col-lg-4  mt-0 mb-2">
                                  <Button
                                    onClick={() => {
                                      setIsNewShipping(true);
                                      setIsNewShippingForm(true);
                                    }}
                                    style={{
                                      height: "165px",
                                    }}
                                    className="btn-addAddress"
                                  >
                                    Add new Address
                                  </Button>
                                </div>
                                {listOfAddress
                                  ?.filter((ead) => {
                                    const currentCountryCode =
                                      countryCurrency == "INR"
                                        ? "101"
                                        : countryCurrency == "USD"
                                        ? "231"
                                        : countryCurrency == "GBP"
                                        ? "230"
                                        : countryCurrency == "CAD"
                                        ? "31"
                                        : "101";

                                    return (
                                      ead?.country_id == currentCountryCode
                                    );
                                  })
                                  ?.map((item) => {
                                    // const addressInfo = dataFormate(item);
                                    // console.log(item)
                                    const addressInfo = {
                                      firstname: item?.firstname,
                                      lastname: item?.lastname,
                                      // name: item.name,
                                      name:
                                        item?.firstname || item?.lastname
                                          ? `${item?.firstname ?? ""} ${
                                              item?.lastname ?? ""
                                            }`
                                          : item?.name ?? "",
                                      city: item.city,
                                      state: item.state.name,
                                      address1: item.address1,
                                      address2: item.address2,
                                      postal_code: item.postal_code,
                                      country: item.country.name,
                                      id: item.id,
                                    };

                                    return (
                                      <>
                                        <AddressCard
                                          {...addressInfo}
                                          key={item.id}
                                          setSelectedAddress={
                                            setSelectedShippingAddressId
                                          }
                                          selectedId={selectedShippingAddressId}
                                          onSelectAddress={() => {
                                            setIsNewShipping(false);
                                            setIsNewShippingForm(false);
                                          }}
                                        />
                                      </>
                                    );
                                  })}
                              </div>
                              <div className="text-start mt-4 mb-4">
                                <Button
                                  variant="primary"
                                  className="btn btn-theme btn-white-outline btn-white-outline-hover text-dark1 border-dark1 px-4"
                                  // style={{
                                  //   color: "white",
                                  // }}
                                  disabled={!selectedShippingAddressId}
                                  onClick={() => {
                                    setSaveShippingAddress(true);
                                  }}
                                >
                                  Save & Continue
                                </Button>
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>

            {/* billing address */}
            {/* {!!saveShippingAddress && ( */}
            <div className="border-top-2 border-bottom-2 pt-4 pb-4">
              <div>
                <h4 className="fw-bold">Billing Information</h4>
              </div>

              {!!saveShippingAddress && (
                <div
                  className="pt-4"
                  style={
                    !!saveBillingAddress && !isNewBillingForm && !isEditBilling
                      ? { height: "230px" }
                      : {}
                  }
                >
                  {/* billing Address */}
                  <div className="checkout-sec">
                    {isNewBillingForm ? (
                      <>
                        <NewBillingForm
                          setIsNewShipping={setIsNewShippingForm}
                          handleFromAddress={handleFromAddress}
                          newBillingAddress={newBillingAddress}
                          setIsNewShippingForm={setIsNewShippingForm}
                          setIsEditBilling={setIsEditBilling}
                          userAddressListSchema={userAddressListSchema}
                          stateList={stateList}
                          countryList={countryList}
                          setNewShippingAddress={setNewShippingAddress}
                          setSelectedShippingAddressId={
                            setSelectedShippingAddressId
                          }
                          // cartShippingFields={cartShippingFields}
                          cartShippingFields={
                            countryCurrency === hideCountryState
                              ? cartShippingFields?.filter(
                                  (el) => el?.name !== "state_id"
                                )
                              : cartShippingFields
                          }
                          setNewBillingAddress={setNewBillingAddress}
                          setIsNewBillingForm={setIsNewBillingForm}
                          setSaveBillingAddress={setSaveBillingAddress}
                        />
                      </>
                    ) : (
                      <>
                        {isEditBilling ? (
                          <>
                            <button
                              className="btn btn-link fw-bold text-primary"
                              onClick={() => {
                                setSaveBillingAddress(false);
                                // setSelectedBillingAddressId(null);
                                // setSelectedBillingAddress(null);
                                // setIsSameShipping(false);
                              }}
                            >
                              back
                            </button>
                            <CustomContainer className="contact-form position-relative pb-4">
                              <GlobalForm
                                initialValues={selectedBillingAddress}
                                validationSchema={userAddressListSchema}
                                dynamicOption={{
                                  state: stateList?.map((stat) => ({
                                    label: stat.name,
                                    value: stat.id,
                                  })),
                                  country: countryList?.map((stat) => ({
                                    label: stat.name,
                                    value: stat.id,
                                  })),
                                }}
                                onSubmit={async (values) => {
                                  // setState(values);
                                  setSelectedBillingAddress(values);
                                  setIsNewBillingForm(false);
                                  setIsEditBilling(false);
                                  setSaveBillingAddress(true);
                                }}
                                fields={cartShippingFields}
                                btnText="Save & Continue"
                                btnSet="start"
                              />
                            </CustomContainer>
                          </>
                        ) : (
                          <>
                            {saveBillingAddress ? (
                              <SelectedAddressCard
                                isNew={isNewBilling}
                                newAddress={newBillingAddress}
                                selectedAddress={selectedBillingAddress}
                                stateList={stateList}
                                countryList={countryList}
                                backHandler={() => {
                                  setSaveBillingAddress(false);
                                  // setSelectedBillingAddressId(null);
                                  // setSelectedBillingAddress(null);
                                  setIsSameShipping(false);
                                }}
                              />
                            ) : (
                              <>
                                <Form.Check // prettier-ignore
                                  type="checkbox"
                                  id={`default`}
                                  label="Same as shipping Address"
                                  className="mb-4"
                                  // defaultChecked={isSameShipping}
                                  checked={
                                    selectedShippingAddressId ===
                                    selectedBillingAddressId
                                  }
                                  onChange={(e) => {
                                    // console.log(e.target.checked, "Checkbox");
                                    setIsSameShipping(e.target.checked);
                                    if (!e.target.checked) {
                                      setSelectedBillingAddressId(null);
                                    }
                                    if (e.target.checked) {
                                      setSelectedBillingAddressId(
                                        selectedShippingAddressId
                                      );
                                    }
                                  }}
                                />
                                <ListofAddressCard
                                  selectedShippingAddressId={
                                    selectedShippingAddressId
                                  }
                                  selectedBillingAddressId={
                                    selectedBillingAddressId
                                  }
                                  setIsSameShipping={setIsSameShipping}
                                  setSelectedBillingAddressId={
                                    setSelectedBillingAddressId
                                  }
                                  setIsNewBilling={setIsNewBilling}
                                  setIsNewBillingForm={setIsNewBillingForm}
                                  listOfAddress={listOfAddress}
                                  isSameShipping={isSameShipping}
                                  onNewHandler={() => {
                                    setIsNewBilling(true);
                                    setIsNewBillingForm(true);
                                    // setIsSameShipping(false);
                                  }}
                                  onClickHandler={() => {
                                    setSaveBillingAddress(true);
                                    if (!!isSameShipping) {
                                      setSelectedBillingAddress(
                                        selectedShippingAddress
                                      );
                                      setSelectedBillingAddressId(
                                        selectedBillingAddressId
                                      );
                                    }
                                  }}
                                />
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
            {/* )} */}

            <div className="py-4">
              <Button
                variant="primary"
                // className="px-4 py-2"
                className="btn btn-theme btn-white-outline btn-white-outline-hover text-dark1 border-dark1 px-4"
                // style={{
                //   color: "white",
                // }}
                type="submit"
                disabled={!saveBillingAddress || !saveShippingAddress}
                onClick={handleBilling}
              >
                Proceed to Payment
              </Button>
            </div>
          </div>

          {/* side 2 */}
          <div className="col-lg-5 col-xxl-4">
            <div className="order-summary">
              <h5 className="text-center fw-bold mb-5 ">Order Summary</h5>
              <div className="cartItem-list">
                {cartItems.map((item) => {
                  const produtInfo = {
                    name: item?.product?.title,
                    caption: item?.product?.caption,
                    sku: item?.product?.sku,
                    type: item?.product?.Necklaces,
                    imgUrl: item?.product?.featured_image[0]?.original_url,
                    hoverImageUrl:
                      item?.product?.featured_image[1]?.original_url,
                    id: item?.product?.id,
                    // price: item?.product?.product_price,
                    price: item?.amount,
                    currency: item?.currency,
                    qty: item.qty,
                    cartProductId: item.id,
                  };
                  return <SummaryCartItem {...produtInfo} />;
                })}
              </div>
              <table className="table mt-4">
                <tbody>
                  <tr>
                    <td> Subtotal:</td>
                    <td className="text-right">
                      {currencyIcon(
                        cartItems?.[0]?.currency ?? countryCurrency
                      )}

                      {formatCurrency(total)}
                    </td>
                  </tr>
                  <tr>
                    <td> Shipping:</td>
                    <td className="text-right">
                      {/* {currencyIcon(countryCurrency)}{" "} */}
                      Free
                      {/* {currencyIcon(
                        cartItems?.[0]?.currency ?? countryCurrency
                      )}
                      {formatCurrency(0.0)} */}
                    </td>
                  </tr>
                  <tr>
                    <td> Tax:</td>
                    <td className="text-right">
                      {currencyIcon(
                        cartItems?.[0]?.currency ?? countryCurrency
                      )}

                      {formatCurrency(0.0)}
                    </td>
                  </tr>
                  <tr className="border-bottom-2">
                    <td className="pb-2">Order Total:</td>
                    <td className="text-right pb-2">
                      {currencyIcon(
                        cartItems?.[0]?.currency ?? countryCurrency
                      )}

                      {formatCurrency(total)}
                    </td>
                  </tr>
                  <tr>
                    <td className="fw-bold pt-2">Total Payable Amount:</td>
                    <td className="text-right fw-bold pt-2">
                      {currencyIcon(
                        cartItems?.[0]?.currency ?? countryCurrency
                      )}

                      {/* <LiaRupeeSignSolid /> */}
                      {formatCurrency(total)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </CustomContainer>
    </>
  );
}

export default Checkout;
