import React from "react";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "../../Hooks/useMeadiaQuery";
import { imageURL2 } from "../../images";
import SliderComponent from "../Slider/sliderComponent";
import { CustomContainer } from "../common/global-display";

const categoryDummyData = [
  {
    title: "Fashion Pendants",
    link: "/fashion-pendants",
    image: imageURL2?.image_171,
  },
  {
    title: "Eternity Rings",
    link: "/eternity-bands",
    image: imageURL2?.image_172,

  },
  {
    title: "Anniversary Ring",
    link: "/anniversary-bands",
    image: imageURL2?.image_173,

  },
  {
    title: "Bracelets",
    link: "/tennis-bracelets",
    image: imageURL2?.image_174,

  },
  {
    title: "Solitaire Rings",
    link: "/solitaire-engagement-rings",
    image: imageURL2?.image_175,

  },
  {
    title: "Necklaces",
    link: "/fashion-necklaces",
    image: imageURL2?.image_176,

  },
  {
    title: "Bangles",
    link: "/bangles",
    image: imageURL2?.image_177,

  },
];
const ShopByCategory = () => {
  const navigate = useNavigate();
  const isAboveDesktop = useMediaQuery("(min-width: 768px)");
  const cardData = categoryDummyData?.map((categoryData, index) => {
    return (
      <div key={index} className="px-3" style={{ padding: "0 15px" }}>
        <div className="slider-card slider-card-zoom">
          <img
            className="img-fluid cursor-pointer"
            src={categoryData?.image}
            alt={categoryData?.title}
            style={{
              width: "100%",
              height: "auto",
              objectFit: "cover",
              aspectRatio: "3/4",
            }}
            onClick={() => navigate(categoryData?.link)}
          />
        </div>
        <h5
          className="text-center my-2 cursor-pointer"
          onClick={() => navigate(categoryData?.link)}
        >
          {categoryData?.title}
        </h5>
      </div>
    );
  });
  return (
    // <ProductSlider categoryDummyData={categoryDummyData} />
    <CustomContainer className="my-6 collection-slide">
      <SliderComponent
        title={"Most Popular"}
        responsive={responsive}
        slidesToShow={3.5}
        data={cardData}
        // infinite
      />
    </CustomContainer>
  );
};

export default ShopByCategory;

const responsive = [
  {
    breakpoint: 1024,
    settings: {
      slidesToShow: 3.5,
      slidesToScroll: 1,
    },
  },
  {
    breakpoint: 769,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 1,
    },
  },
  {
    breakpoint: 600,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 1,
    },
  },
  {
    breakpoint: 480,
    settings: {
      slidesToShow: 1.05,
      slidesToScroll: 1,
    },
  },
];
