import React, { useState, useRef, useEffect } from "react";

const EllipsisText = ({ text, maxLines }) => {
  const [expanded, setExpanded] = useState(false);
  const containerRef = useRef(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const computedStyle = window.getComputedStyle(container);
    const lineHeight = parseInt(computedStyle.lineHeight);
    const maxHeight = lineHeight * maxLines;

    setIsOverflowing(container.scrollHeight > maxHeight);
  }, [text, maxLines]);

  const toggleLines = () => {
    setExpanded(!expanded);
  };

  const ellipsisStyle = {
    display: "-webkit-box",
    WebkitLineClamp: expanded || !isOverflowing ? "unset" : maxLines,
    overflow: "hidden",
    textOverflow: "ellipsis",
    WebkitBoxOrient: "vertical",
    // maxHeight: expanded ? "none" : lineHeight * maxLines + "px",
  };

  return (
    <div ref={containerRef}>
      <h4 style={ellipsisStyle}>{text}</h4>
      {isOverflowing && (
        <h5
          className="read-more  fw-bold cursor-pointer "
          onClick={toggleLines}
        >
          {expanded ? "See Less" : "See More"}
        </h5>
      )}
    </div>
  );
};

export default EllipsisText;
