/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  newTransformProductData,
  transformProductData,
} from "../../constants/function";
import {
  addToFavourite,
  getFavouriteList,
  removeFavoriteList,
} from "../../features/cart/cartSlice";
import ProductCard from "../product/ProductCard";
import SliderComponent from "../Slider/sliderComponent";
import products from "./weddingpopular.json";
import { CustomContainer } from "../common/global-display";
const PopularWeddingRings = () => {
  const disptach = useDispatch();
  // const productReducer = useSelector((state) => state.products);
  const onAddToFavourite = async (data) => {
    // eslint-disable-next-line no-restricted-globals
    alert("Product has been added to your wishlist.");

    // await dispatch(addToFavourite(produtInfo?.[selectedColor?.key]?.id));
    await disptach(addToFavourite(data));
    await disptach(getFavouriteList());
  };

  const handleRemove = async (id) => {
    const conf = confirm(
      "Are you sure you want to remove from your wishlist item?",
    );
    if (!conf) return;
    await disptach(removeFavoriteList(id));
    await disptach(getFavouriteList());
  };
  const [countryCurrency, setCountryCurrency] = useState("INR");

  useEffect(() => {
    setCountryCurrency(localStorage.getItem("currency"));
  }, []);
  const cardData = products?.map((item) => {
    // const item_detail = JSON?.parse(item?.item_detail);
    const produtInfo = item?.product_variation
      ? transformProductData(item, countryCurrency)
      : newTransformProductData(item, countryCurrency);

    return (
      <>
        <ProductCard
          className="w-full px-2  pointer-disable"
          produtInfo={produtInfo}
          // {...produtInfo}
          key={item.id}
          item={item}
          onAddToFavourite={onAddToFavourite}
          handleRemove={handleRemove}
        />
      </>
    );
  });
  return (
    <CustomContainer className="collection-slide">
      <SliderComponent
        title={"Popular Wedding Rings"}
        responsive={responsive}
        slidesToShow={3.5}
        data={cardData}
        // infinite
      />
    </CustomContainer>
  );
};

export default PopularWeddingRings;
const responsive = [
  {
    breakpoint: 1024,
    settings: {
      slidesToShow: 3.5,
      slidesToScroll: 1,
    },
  },
  {
    breakpoint: 769,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 1,
    },
  },
  {
    breakpoint: 600,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 1,
    },
  },
  {
    breakpoint: 480,
    settings: {
      slidesToShow: 1.05,
      slidesToScroll: 1,
    },
  },
];
