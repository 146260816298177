import React from "react";
import { Link } from "react-router-dom";
import useMediaQuery from "../../Hooks/useMeadiaQuery";
import { CustomContainer } from "../common/global-display";

const HomeHeroSection = ({ text, image }) => {
  const isAboveDesktop = useMediaQuery("(min-width: 576px)");

  return (
    <section
      className="container-fluid hero-section"
      style={{
        backgroundImage: `url(${
          isAboveDesktop ? image?.image_1 : image?.image_79
        })`,
        // background:"black"
      }}
    >
      <CustomContainer className="mt-0 mb-0 text-center">
        <div className="hero-text">
          {/* <h6 data-aos="zoom-out-down">{text?.text_1}</h6>
          <h1 className="mb-lg-4 mb-3 text-white" data-aos="zoom-out-down">
            {text?.text_2}
          </h1> */}
          {text?.text_1 && (
            <h6 data-aos-easing="ase-in-back">{text?.text_1}</h6>
          )}
          {text?.text_2 && (
            <h1
              className="mb-lg-4 mb-3 text-white"
              data-aos-easing="ase-in-back"
            >
              {text?.text_2}
            </h1>
          )}
          <Link
            to="/fashion-pendants"
            className="btn btn-theme-1 btn-outline text-white"
          >
            SHOP NOW
          </Link>
          {/* <a href="/category" className="btn btn-theme btn-secondary">
            Explore More
            </a> */}
        </div>
      </CustomContainer>
      {/* <a href="#topCollection" className="down-arrow">
          <img src={imageURL.downArrow} className="img-fluid" alt="Arrow" />
          </a> */}
    </section>
  );
};

export default HomeHeroSection;
