import React, { useRef, useState } from "react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import Slider from "react-slick";

const SliderComponent = ({
  infinite = false,
  dots = false,
  speed = 500,
  slidesToShow = 1,
  slidesToScroll = 1,
  arrow = false,
  swipe = true,
  prevArrow,
  nextArrow,
  responsive,
  buttonPosY = "50%",
  data = [],
  title = null,
  ...props
}) => {
  // const [currentSlide, setCurrentSlide] = useState(0);
  const [disabledNext, setDisabledNext] = useState(false);
  const [disabledPrev, setDisabledPrev] = useState(true);

  let sliderRef = useRef(null);

  const next = () => {
    // console.log(disabledPrev)
    if (!disabledNext) {
      sliderRef.slickNext();
    }
  };
  const previous = () => {
    // console.log(disabledPrev)
    if (!disabledPrev) {
      sliderRef.slickPrev();
    }
  };
  const settings = {
    infinite: infinite,
    dots: dots,
    speed: speed,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToScroll,
    arrows: !title ? true : arrow,
    beforeChange: (current, next) => {
      // setCurrentSlide(next);
      if (next === 0) {
        setDisabledPrev(true);
      } else {
        setDisabledPrev(false);
      }
      if (next === data.length - slidesToShow) {
        setDisabledNext(true);
      } else {
        setDisabledNext(false);
      }
    },
    prevArrow: <PrevArrow buttonPosY={buttonPosY} onClick={previous} />,
    nextArrow: <NextArrow buttonPosY={buttonPosY} onClick={next} />,
    responsive: responsive ?? [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div>
        {title && (
          <p className="d-flex justify-content-between mb-0">
          {/* <p className="d-flex align-items-center justify-content-between mb-0"> */}
            <h2 className="captilize-text text-center my-4 mt-0">{title}</h2>
            {title && (
              <span className="d-flex gap-2 justify-content-between">
                <PrevArrow
                  style={{
                    top: buttonPosY,
                  }}
                  onClick={previous}
                />
                <NextArrow
                  style={{
                    top: buttonPosY,
                  }}
                  onClick={next}
                />
              </span>
            )}
          </p>
        )}
        {/* <p className="d-flex align-items-center justify-content-center  justify-content-md-between">
                    <h3 className="text-center my-4">{title}</h3>
                    {isAboveDesktop && <span className='d-flex align-items-center gap-2 justify-content-between'>
                        <PrevArrow onClick={previous} />
                        <NextArrow onClick={next} />
                    </span>}
                </p> */}
        <Slider
          ref={(slider) => {
            sliderRef = slider;
          }}
          {...settings}
          className="get-inspired-slider"
        >
          {data}
        </Slider>
      </div>
    </>
  );
};

export default SliderComponent;

export const PrevArrow = (props) => {
  const { className, style, onClick, buttonPosY } = props;

  return (
    <FiChevronLeft
      className={className}
      style={{
        ...style,
        top: buttonPosY,
        fontSize: "24px",
        color: "black",
        height: "30px",
        width: "30px",
      }}
      onClick={onClick}
    />
  );
};

export const NextArrow = (props) => {
  const { className, style, onClick, buttonPosY } = props;
  return (
    <FiChevronRight
      className={className}
      style={{
        ...style,
        top: buttonPosY,
        cursor: "pointer",
        fontSize: "24px",
        color: "black",
        height: "30px",
        width: "30px",
      }}
      onClick={onClick}
    />
  );
};
