import axios from "axios";
import { useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import "./App.scss";

import { changeCartCurrency, isLogin } from "./constants/function";
import RoutePage from "./constants/routes";
import { UpdateRegion } from "./constants/urls";
import {
  addToCart,
  addToFavourite,
  getCartDetails,
  getFavouriteList,
} from "./features/cart/cartSlice";
import { getPageText } from "./features/text/getTextSlice";
import { getUserdetails } from "./features/user/getUserDetailsSlice";
import { getCountryPrice } from "./helper/constant";
// import { getCountryPrice } from "./helper/constant";
// import { response } from "express";

function App() {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (!!localStorage.getItem("token")) {
      dispatch(getUserdetails());
    }
  }, [localStorage.getItem("token")]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location.pathname]);

  useEffect(() => {
    getCountryPrice().then(async (resp) => {
      localStorage.setItem("currency", resp);
      if (isLogin()) {
        const currency =
          resp === "INR"
            ? 0
            : resp === "USD"
              ? 1
              : resp === "GBP"
                ? 2
                : resp === "CAD"
                  ? 3
                  : 1;
        const resPosnseofUpdate = await axios.get(
          `${UpdateRegion}/${currency}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          },
        );
        // console.log(resPosnseofUpdate.data.success,"locastion")
        if (resPosnseofUpdate?.data?.success) {
          // window.location.reload()
        }
      } else {
        changeCartCurrency(resp);
      }
    });
  }, []);

  useEffect(() => {
    dispatch(
      getPageText(
        location.pathname == "/"
          ? `${process.env.REACT_APP_WEB_URL}${location.pathname}`
          : `${process.env.REACT_APP_WEB_URL}${location.pathname}/`,
      ),
    );
  }, [location.pathname]);

  useEffect(() => {
    if (!localStorage.getItem("token")) return;
    async function getBulkData() {
      const cart = JSON.parse(localStorage.getItem("cart")) || [];
      const wishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
      if (cart?.length <= 0 || wishlist?.length <= 0) return;
      const wishlistPayload = wishlist?.map((item) => {
        return item?.id;
      });

      const cartData = cart?.map((item) => {
        return {
          ...item,
          id: item?.id,
          qty: item?.qty,
          size: +item?.product?.Productsize,
        };
      });
      await dispatch(addToCart(cartData));
      await dispatch(addToFavourite(wishlistPayload));
      await dispatch(getCartDetails());
      await dispatch(getFavouriteList());
      // Clear localStorage
      localStorage.removeItem("cart");
      localStorage.removeItem("wishlist");
    }
    getBulkData();
  }, []);

  const ProtectedRoute = ({ children }) => {
    if (!localStorage.getItem("token")) {
      // Redirect them to the /login page, but save the current location they were
      // trying to go to when they were redirected. This allows us to send them
      // along to that page after they login, which is a nicer user experience
      // than dropping them off on the home page.
      return <Navigate to="/login" replace />;
    }

    return children ? children : <Outlet />;
  };

  return (
    <div className="App">
      <RoutePage />
      <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        toastOptions={{
          success: {
            duration: 3000,
            theme: {
              primary: "green",
              secondary: "black",
            },
          },
        }}
      />
    </div>
  );
}

export default App;
